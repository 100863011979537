<template>
  <div>
    <b-card
      title="ค้นหาสมาชิก"
      no-body
    >
      <b-card-body>
        <!-- <div class="d-flex justify-content-between  flex-wrap"> -->
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="เบอร์โทร / ยูส"
                label-for="mc-first-name"
              >
                <b-form-input
                  id="mc-first-name"
                  v-model="userInput"
                  placeholder="เบอร์โทร / ยูส"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group />
            </b-col>
            <!-- submit and reset -->
            <b-col>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                @click="searchMember"
              >
                ค้นหา
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                @click="clearSearch"
              >
                เคลียร์
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card-body>
    </b-card>
    <b-overlay
      id="overlay-background"
      :show="loadingProfile"
      variant="light"
      opacity="0.5"
      :blur="true"
      rounded="sm"
    >
      <b-card
        v-if="memberInfo"
        :title="`ข้อมูลสมาชิก : ${memberInfo.id} (ลำดับการสมัคร)`"
      >
        <b-row v-if="memberInfo">
          <b-col md="3">
            <h5 class="text-capitalize mb-75">
              เบอร์โทร
            </h5>
            <cleave
              id="member-phoneno"
              v-model="memberForm.phoneno"
              class="form-control"
              :raw="false"
              :options="{
                numeral: true,
                stripLeadingZeroes: false,
                blocks: [3, 3, 4],
                delimiters: ['-', '-', '-'],
                numeralThousandsGroupStyle: 'none',
              }"
              placeholder="0XX-XXX-XXXX"
            />
          </b-col>
          <b-col md="3">
            <h5 class="text-capitalize mb-75">
              ชื่อ
            </h5>
            <b-form-input
              id="mc-first-name"
              v-model="memberForm.fname"
              placeholder="ชื่อ"
            />
          </b-col>
          <b-col md="3">
            <h5 class="text-capitalize mb-75">
              นามสกุล
            </h5>
            <b-form-input
              id="mc-last-name"
              v-model="memberForm.lname"
              placeholder="นามสกุล"
            />
          </b-col>
          <b-col md="3">
            <h5 class="text-capitalize mb-75">
              ยูสเซอร์
            </h5>
            <b-form-input
              id="mc-user-name"
              v-model="memberForm.username"
              placeholder="ยูสเซอร์"
              readonly
            />
          </b-col>
          <b-col
            md="3"
            class="mt-10"
          >
            <h5 class="text-capitalize mb-75">
              Line ID
            </h5>
            <!-- <b-form-input
              id="mc-line-id"
              v-model="memberForm.lineid"
              placeholder="ไลน์ไอดี"
            /> -->
            <b-card-text>
              {{ memberInfo.lineid }}
            </b-card-text>
          </b-col>
          <b-col
            md="3"
            class="mt-10"
          >
            <h5 class="text-capitalize mb-75">
              CorePay UserID
            </h5>
            <b-card-text>
              {{ memberInfo.corepay_user_id }}
            </b-card-text>
          </b-col>
          <b-col
            md="3"
            class="mt-10"
          >
            <h5 class="text-capitalize mb-75">
              ช่องทางการสมัคร
            </h5>
            <b-card-text>
              {{ memberInfo.subscription }}
            </b-card-text>
          </b-col>
          <b-col
            md="3"
            class="mt-10"
          >
            <h5 class="text-capitalize mb-75">
              สมัครเมื่อ
            </h5>
            <b-card-text>
              {{ formatDate(memberInfo.created_at, 'YYYY-MM-DD HH:mm:ss', 'DD/MM/YYYY HH:mm') }}
            </b-card-text>
          </b-col>
          <b-col
            md="3"
            class="mt-10"
          >
            <h5 class="text-capitalize mb-75">
              คนชวนมา
            </h5>
            <b-badge
              v-if="!memberInfo.invited_by"
              :variant="'light-danger'"
            >
              ไม่มี
            </b-badge>
            <div v-if="memberInfo.invited_by">
              <span v-if="memberInfo.phoneno.indexOf('X') < 0">{{ (memberInfo.refer_by || {}).phoneno }} - </span>{{ (memberInfo.refer_by || {}).fname }} {{ (memberInfo.refer_by || {}).lname }}
            </div>
          </b-col>
          <b-col
            md="3"
            class="mt-10"
          >
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="primary"
              @click="saveProfile(memberInfo.id, memberForm, 'loadingProfile')"
            >
              บันทึก
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
    <b-row v-if="memberInfo">
      <b-col md="6">
        <b-card
          v-if="memberInfo"
          title="บัญชีฝาก"
        >
          <b-row>
            <b-col>
              <img
                :src="getBankImage(memberInfo.bank_code)"
                style="width: 24px; margin-bottom:5px"
              > {{ memberInfo.bank_name }} {{ memberInfo.bank_acc_no }}
            </b-col>
          </b-row>
          <b-row
            style="margin-top:10px;"
          >
            <b-col md="5">
              <v-select
                v-model="memberForm.bank_code"
                label="text"
                :options="bankListOptions"
                :reduce="bankItem => bankItem.value"
              >
                <template #option="{ text, value }">
                  <img
                    :src="getBankImage(value)"
                    style="width: 24px;"
                  >
                  <span> {{ text }}</span>
                </template>
              </v-select>
            </b-col>
            <b-col md="5">
              <cleave
                id="member-bank-acc"
                v-model="memberForm.bank_acc_no"
                class="form-control"
                :raw="false"
                :options="{
                  numeralThousandsGroupStyle: 'none',
                  numeral: true,
                  stripLeadingZeroes: false,
                }"
                placeholder="เลขที่บัญชี"
              />
            </b-col>
            <b-col md="2">
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="primary"
                @click="saveProfile(memberInfo.id, { bank_code: memberForm.bank_code, bank_acc_no: memberForm.bank_acc_no }, 'loadingProfile')"
              >
                บันทึก
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card
          v-if="memberInfo"
          title="รหัสผ่าน"
        >
          <b-row>
            <b-col md="8">
              <b-form-input
                id="mc-first-name"
                v-model="memberForm.password"
                placeholder="รหัสผ่าน"
              />
            </b-col>
            <b-col md="4">
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="primary"
                @click="saveProfile(memberInfo.id, { password: memberForm.password }, 'loadingProfile')"
              >
                เปลี่ยนรหัสผ่าน
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card
          v-if="memberInfo"
          title="กลุ่มของสมาชิก"
        >
          <b-row>
            <b-col md="8">
              <v-select
                v-model="memberForm.group_id"
                label="name"
                :options="memberGroups"
                :reduce="memberGroupItem => memberGroupItem.id"
              >
                <template #option="{ name }">
                  <span> {{ name }}</span>
                </template>
              </v-select>
            </b-col>
            <b-col md="4">
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="primary"
                @click="saveProfile(memberInfo.id, { group_id: memberForm.group_id }, 'loadingProfile')"
              >
                บันทึก
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card
          v-if="memberInfo"
          title="บัญชีรับเติมเงิน"
        >
          <b-row>
            <b-col md="8">
              <v-select
                v-model="memberForm.dep_acc"
                label="title"
                :options="bankAccounts"
                :reduce="bankItem => bankItem.id"
              >
                <template #option="data">
                  <span>{{ data.acc_type.replace('IN','ฝาก') }} {{ bankMaps[data.bank_code] }} {{ data.acc_no }} {{ data.acc_name }}</span>
                </template>
              </v-select>
            </b-col>
            <b-col md="4">
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="primary"
                @click="saveProfile(memberInfo.id, { dep_acc: memberForm.dep_acc }, 'loadingProfile')"
              >
                บันทึก
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-card
      v-if="memberInfo"
      title="รายการฝาก ถอน โปรโมชั่น"
    >
      <b-table
        ref="receivedPromotionsTable"
        striped
        hover
        responsive
        class="position-relative"
        :busy.sync="isBusy"
        :per-page="tableQuery.page_size"
        :current-page="tableQuery.page"
        :items="walletItems"
        :fields="fields"
      >
        <template #cell(username)="data">
          {{ data.item.phoneno }}<br>
          {{ data.item.username }}
        </template>
        <template #cell(fullname)="data">
          {{ `${data.item.fname} ${data.item.lname}` }}
        </template>
        <template #cell(note)="data">
          {{ `[${data.item.platform}][${data.item.txtype}` }}] {{ `${data.item.note}` }}
        </template>
        <template #cell(amount)="data">
          <div style="text-align: right;">
            {{ `${numberWithCommas(data.item.amount)}` }}
          </div>
        </template>
        <template #cell(balance)="data">
          <div style="text-align: right;">
            {{ `${numberWithCommas(data.item.balance)}` }}
          </div>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="tableQuery.page_size"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="tableQuery.page"
            :total-rows="totalWalletRows"
            :per-page="tableQuery.page_size"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card>
    <b-card
      v-if="memberInfo"
      title="50 กิจกรรมล่าสุด"
    >
      <b-table
        ref="receivedPromotionsTable"
        striped
        hover
        responsive
        class="position-relative"
        :per-page="15"
        :current-page="localVariables.activities_page"
        :items="userActivities"
        :fields="activity_fields"
      />
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="localVariables.activities_page"
            :total-rows="userActivities.length"
            :per-page="15"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
// import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard, BTable, BBadge, BFormGroup, BFormSelect, BPagination, BFormInput, BButton, BCardBody, BRow, BCol, BForm, BCardText, BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import vSelect from 'vue-select'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BTable,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BCardText,
    // BInputGroup,
    BFormInput,
    // BInputGroupAppend,
    BButton,
    BCardBody,
    BRow,
    BCol,
    BForm,
    BOverlay,
    Cleave,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loadingProfile: false,
      userInput: null,
      isBusy: false,
      localVariables: {},
      pageOptions: [50, 100, 200, 500, 1000],
      bankListOptions: JSON.parse(localStorage.getItem('bank-lists') || '[]'),
      txtypes: [{ title: 'ทั้งหมด', value: '' }, { title: 'รายการฝาก', value: 'DEPOSIT' }, { title: 'รายการแจ้งถอน', value: 'WITHDRAW' }, { title: 'รายการคืนเงิน', value: 'REFUND' }, { title: 'โปรโมชั่น', value: 'PROMOTION' }, { title: 'วางเดิมพัน', value: 'BET' }, { title: 'เคลียร์บิล', value: 'BETWIN' }, { title: 'เดิมพันที่ถูกยกเลิก', value: 'BETCANCEL' }],
      platformOpts: [{ title: 'ทั้งหมด', value: '' }, { title: 'กระเป๋าเงิน', value: 'WALLET' }, { title: 'SA', value: 'SA' }, { title: 'SEXY', value: 'AE' }, { title: 'WM', value: 'WM' }, { title: 'DG', value: 'DG' }, { title: 'CQ9', value: 'CQ9' }, { title: 'JOKER', value: 'JOKER' }, { title: 'JDB', value: 'JDB' }, { title: 'MG', value: 'MG' }, { title: 'SBO', value: 'SBO' }, { title: 'HOTGRAPH', value: 'HOTGRAPH' }, { title: 'LOTTO', value: 'LOTTO' }],
      totalWalletRows: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      memberGroups: [],
      bankAccounts: [],
      filterOn: [],
      bankMaps: {
        '004': 'kbank', '014': 'scb', '006': 'krungthai', TMW: 'tmw',
      },
      tableQuery: {
        page: 1,
        page_size: 50,
      },
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'created_at', label: 'วันที่' },
        { key: 'username', label: 'ยูส/เบอร์' },
        { key: 'note', label: 'รายละเอียด' },
        { key: 'amount', label: 'จำนวน' },
        { key: 'balance', label: 'คงเหลือ' },
        { key: 'ref2', label: 'อ้างอิง 2' },
        { key: 'ref3', label: 'อ้างอิง 3' },
        { key: 'platform', label: 'ระบบ' },
      ],
      activity_fields: [
        { key: 'date', label: 'วันที่', formatter: val => this.formatDate(val) },
        { key: 'hour', label: 'เวลา (นาฬิกา)' },
        {
          key: 'stat_type',
          label: 'ประเภท',
          formatter: val => val.replace('DEPOSIT', 'ฝากเงิน').replace('REGISTER', 'สมัคร').replace('BET', 'เข้าเล่น'),
        },
        { key: 'game', label: 'ค่ายเกมส์' },
        { key: 'ip', label: 'IP' },
      ],
      /* eslint-disable global-require */
      walletItems: [
      ],
      userActivities: [

      ],
      memberInfo: null,
      memberForm: {},
      limitedPermission: true,
      /* eslint-disable global-require */
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    this.fetchMemberGroups()
    this.fetchBankAccounts()
    this.totalWalletRows = this.walletItems.length
    if (this.$route.params.user !== null && this.$route.params.user !== undefined) {
      this.userInput = this.$route.params.user
      this.fetchRecords()
    }
  },
  methods: {
    searchMember() {
      this.fetchRecords()
      // this.$refs.receivedPromotionsTable.refresh()
    },
    clearSearch() {
      this.userInput = null
      this.tableQuery = {
        page: 1,
        page_size: 100,
      }
      this.$refs.receivedPromotionsTable.refresh()
    },
    async fetchRecords(ctx) {
      console.log('[fetchRecords]', ctx)
      this.userActivities = []
      let fieldName = 'phoneno'
      if (/[a-zA-Z]/.test(this.userInput)) {
        fieldName = 'username'
        this.tableQuery.username = this.userInput.replace(/[^\w\s]/gi, '')
        delete this.tableQuery.phoneno
      } else {
        this.tableQuery.phoneno = this.userInput.replace(/[^\w\s]/gi, '')
        delete this.tableQuery.username
      }
      let query = ''
      if (this.tableQuery !== {}) {
        Object.keys(this.tableQuery).forEach((key, idx) => {
          if (idx > 0) query += '&'
          // if (this.tableQuery[key].\\)
          //   query += `${key}=${encodeURIComponent(this.tableQuery[key].value)}`
          // else
          query += `${key}=${encodeURIComponent(this.tableQuery[key])}`
        })
      }
      console.log(query)

      if ((this.userInput === null || this.userInput === undefined)) {
        this.walletItems = []
        return this.walletItems
      }

      const { data: memberApiResult } = await this.$http.get(`/users/user-info?with_deposits=N&${fieldName}=${this.userInput}&searchBy=${fieldName}&user=${this.userInput}`)
      this.memberInfo = memberApiResult.user
      this.memberForm = { ...memberApiResult.user }
      this.limitedPermission = memberApiResult.limitedPermission

      const { data: _wallettx } = await this.$http.get(`wallet-transactions?${fieldName}=${this.userInput}&platform=WALLET`)
      this.walletItems = _wallettx.wallet_transactions.data
      this.totalWalletRows = _wallettx.wallet_transactions.total

      this.fetchActivity()
      //
      return this.walletItems
    },
    async fetchActivity() {
      const { data: _activitiesResult } = await this.$http.post('users/activities', { user_id: this.memberInfo.id })
      console.log('fetchActivity', _activitiesResult)
      this.userActivities = _activitiesResult.activities
    },
    async fetchMemberGroups() {
      const { data: { member_groups: memberGroups } } = await this.$http.get('users/groups')
      this.memberGroups = memberGroups
      return this.memberGroups
    },
    async fetchBankAccounts() {
      const { data: _bankaccounts } = await this.$http.get('bankaccounts?mode=v2')
      this.bankAccounts = _bankaccounts.bankaccounts.filter(i => i.acc_type === 'IN').map(i => ({
        ...i, title: `${this.bankMaps[i.bank_code]} ${i.acc_no} ${i.acc_name}`, acc_type: i.acc_type, queue_name: i.bank_alias,
      }))
    },
    async saveProfile(id, changes, loadingRef) {
      this[loadingRef] = true
      const allowChanges = ['phoneno', 'fname', 'lname', 'lineid', 'bank_code', 'bank_acc_no', 'level', 'dep_acc', 'password', 'group_id']
      const changeMade = {}
      Object.keys(changes).forEach(keyItem => {
        if (keyItem === 'bank_acc_no') {
          if (this.memberInfo[keyItem].replace(/X/ig, '') !== changes[keyItem])
            changeMade[keyItem] = changes[keyItem]
          return
        }
        if (allowChanges.indexOf(keyItem) >= 0 && changes[keyItem] !== this.memberInfo[keyItem])
          changeMade[keyItem] = changes[keyItem]
      })
      try {
        if (changeMade.phoneno)
          changeMade.phoneno = changeMade.phoneno.replace(/-/ig, '')
        const { data: saveProfileResult } = await this.$http.put(`users/${id}`, changeMade)
        if (saveProfileResult.success) {
          this.memberInfo = saveProfileResult.user
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: 'บันทึกข้อมูลลูกค้า',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: 'บันทึกการแก้ไขข้อมูลผู้ใช้งานเรียบร้อย',
            },
          })
        }
      } catch (err) {
        console.log(err)
      }
      this[loadingRef] = false
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.mt-10 {
  margin-top: 20px;
}
.text-right {
  text-align: right;
}
</style>
